import Swiper, { Autoplay, Pagination, EffectFade } from 'swiper';
Swiper.use([Autoplay, Pagination, EffectFade]);

const swiper = new Swiper('.swiper-container', {
  effect: 'fade',
  loop: false,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});
